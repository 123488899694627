import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/chat": [3],
		"/chat/cosmic-questions": [4],
		"/connections": [5],
		"/connections/content": [6],
		"/connections/invite-upsell": [7],
		"/connections/privacy": [8],
		"/content": [9],
		"/dashboard": [10],
		"/design": [11],
		"/design/connections": [12],
		"/design/connections/connections": [13],
		"/design/connections/content": [14],
		"/design/connections/copy-link": [15],
		"/design/connections/create-group": [16],
		"/design/connections/empty": [17],
		"/design/connections/invite-link": [19],
		"/design/connections/invite-upsell": [20],
		"/design/connections/invite": [18],
		"/design/connections/privacy": [21],
		"/design/connections/test-landing": [22],
		"/design/content": [23],
		"/design/cosmic-guide": [24],
		"/design/cosmic-guide/add-connection": [25],
		"/design/cosmic-guide/connection": [26],
		"/design/cosmic-guide/cosmic-guide": [27],
		"/design/cosmic-guide/cosmic-questions": [28],
		"/design/cosmic-guide/history2": [30],
		"/design/cosmic-guide/history": [29],
		"/design/cosmic-guide/library-prompts": [32],
		"/design/cosmic-guide/library": [31],
		"/design/cosmic-guide/select-connection": [33],
		"/design/cosmic-guide/thread": [34],
		"/design/cosmic-guide/welcome-1": [35],
		"/design/cosmic-guide/welcome-2": [36],
		"/design/enneagram": [37],
		"/design/loading": [38],
		"/design/modal": [39],
		"/design/signup": [40],
		"/design/signup/change-password": [41],
		"/design/signup/create-password-id": [43],
		"/design/signup/create-password": [42],
		"/design/signup/forgot-password-send": [45],
		"/design/signup/forgot-password": [44],
		"/design/signup/login": [46],
		"/design/soulprint": [47],
		"/design/survey": [48],
		"/design/survey/astrology": [49],
		"/design/survey/enneagram-cards": [50],
		"/design/survey/enneagram-instructions-01": [51],
		"/design/survey/enneagram-instructions-02": [52],
		"/design/survey/enneagram-paragraphs": [53],
		"/design/survey/enneagram-tiebreak-02": [55],
		"/design/survey/enneagram-tiebreak-intro": [56],
		"/design/survey/enneagram-tiebreak": [54],
		"/design/survey/gender": [57],
		"/design/survey/interests-01": [58],
		"/design/survey/interests-02": [59],
		"/design/survey/job": [60],
		"/design/survey/numerology": [61],
		"/design/survey/relationship": [62],
		"/design/survey/retake": [63],
		"/design/survey/signup": [64],
		"/design/survey/spiraldynamics-01": [65],
		"/design/survey/spiraldynamics-02": [66],
		"/enneagram": [67],
		"/forgot-password": [68],
		"/free-soulprint": [69],
		"/group": [70],
		"/invite": [71],
		"/login": [72],
		"/logout": [73],
		"/new-personality-test": [74],
		"/onboard": [75],
		"/payment": [76],
		"/personality-test-dev": [78],
		"/personality-test": [77],
		"/premium": [79],
		"/primary": [80],
		"/relationship": [81],
		"/reset-password": [82],
		"/secondary": [83],
		"/soulprint": [84],
		"/teritiary": [85],
		"/workplace": [86]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';